<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background:new 0 0 511.999 511.999;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M355.194,78.406h49.585c14.411,0,26.093,11.683,26.093,26.093v381.406
	c0,14.411-11.683,26.093-26.093,26.093H107.22c-14.411,0-26.093-11.683-26.093-26.093V104.499c0-14.411,11.683-26.093,26.093-26.093
	h15.496"
    />
    <polyline
      style="fill:#F8F8F8;"
      points="355.191,115.117 393.029,115.117 393.029,463.668 118.96,463.668 118.96,115.117 
	156.798,115.117 "
    />
    <polygon
      style="fill:#E4E4E4;"
      points="351.284,388.974 188.458,388.974 188.458,200.819 170.365,200.819 170.365,388.974 
	141.418,388.974 141.418,407.067 170.365,407.067 170.365,432.395 188.458,432.395 188.458,407.067 351.284,407.067 "
    />
    <rect
      x="214.99"
      y="242.431"
      style="fill:#F64B4A;"
      width="47.039"
      height="123.031"
    />
    <rect
      x="283.14"
      y="294.007"
      style="fill:#65B4BB;"
      width="47.039"
      height="71.442"
    />
    <path
      style="fill:#82DCC7;"
      d="M314.911,52.497C311.529,22.951,286.451,0,255.999,0s-55.528,22.951-58.911,52.497h-40.284v77.038
	h198.392V52.497H314.911z"
    />
    <circle style="fill:#65B4BB;" cx="256.001" cy="57.27" r="18.408" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
